import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import device from "../shared/devices"
import H2 from "./h2"

const StyledContentBlock = styled.div`
  @media ${device.mobile} {
    margin-bottom: 50px;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media ${device.tablet} {
    margin-bottom: 65px;
    grid-column-start: ${props => props.position === 'right' ? 4 : 1};
    grid-column-end: ${props => props.fullWidth || props.position === 'right' ? 7 : 4};
  }

  @media ${device.desktop} {
    margin-bottom: 100px,
  }

`

const ContentBlockHeading = styled(H2)`
  display: ${props => props.title ? 'block' : 'none'};

  @media ${device.mobile} {
    margin-bottom: 20px;
  }

  @media ${device.tablet} {
    margin-bottom: 20px;
  }

  @media ${device.desktop} {
    margin-bottom: 22px;
  }
`

const ContentBlock = ({ title, fullWidth, position, className, children }) => (
  <StyledContentBlock className={className} fullWidth={fullWidth} position={position}>
    <ContentBlockHeading title={title}>{title}</ContentBlockHeading>
    {children}
  </StyledContentBlock>
);

ContentBlock.propTypes = {
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  centered: PropTypes.bool,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ContentBlock.defaultProps = {
  title: '',
  position: 'left',
  fullWidth: false,
  centered: false
}

export default ContentBlock
