import styled from "styled-components"
import device from "../shared/devices"

const H1 = styled.h1`
    font-family: "GTSuperDisplaySuper";
    color: #2700FF;
    padding: 0;

    @media ${device.mobile} {
      font-size: 30px;
      line-height: 35px;
    }

    @media ${device.tablet} {
      font-size: 48px;
      line-height: 70px;
    }

    @media ${device.desktop} {
      font-size: 60px;
      line-height: 70px;
    }
`
export default H1
