import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import device from "../shared/devices"
import H1 from "./h1"

const StyledTitleSection = styled.section`
  @media ${device.mobile} {
    margin-top: 200px;
    margin-bottom: 200px;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media ${device.tablet} {
    margin-top: 250px;
    margin-bottom: 100px;
    grid-column-start: 1;
    grid-column-end: 7;
  }

  @media ${device.tablet} {
    margin-bottom: 110px;
  }
`

const MainHeading = styled(H1)`
  margin: 0;

  @media ${device.mobile} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.tablet} {
    font-size: 48px;
    line-height: 56px;
  }

  @media ${device.desktop} {
    font-size: 60px;
    line-height: 70px;
  }
`

const SubHeading = styled(MainHeading)`
  font-family: "GTSuperDisplayLight";
`

const TitleSection = ({ mainTitle, subTitle }) => (
  <StyledTitleSection>
    <MainHeading>{mainTitle}</MainHeading>
    <SubHeading>{subTitle}</SubHeading>
  </StyledTitleSection>
);

TitleSection.propTypes = {
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string
}

TitleSection.defaultProps = {
  mainTitle: ``,
  subTitle: ``,
}

export default TitleSection
