import styled from "styled-components"
import device from "../shared/devices"

const P = styled.p`
  font-family: "GTSuperTextBook";
  color: #2700FF;
  padding: 0;
  margin: 0;

  @media ${device.mobile} {
    font-size: 18px;
    line-height: 28px;
  }

  @media ${device.desktop} {
    font-size: 22px;
    line-height: 35px;
  }
`
export default P
