import styled from "styled-components"
import device from "../shared/devices"

const H2 = styled.h2`
  font-family: "AktivGrotesk";
  text-transform: uppercase;
  color: #2700FF;
  padding: 0;
  margin: 0;

  @media ${device.mobile} {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.28px;
  }

  @media ${device.tablet} {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.28px;
  }

  @media ${device.desktop} {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.6px;
  }
`
export default H2
