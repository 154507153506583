import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleSection from "../components/title-section"
import ContentBlock from "../components/content-block"
import P from "../components/p"

const IndexPage = () => (
  <Layout>
    <SEO title="Tech Team Berlin" />
    <TitleSection
      mainTitle="Bye 👋"
      subTitle="Tech Team Berlin is no longer active."
    />
    <ContentBlock fullWidth>
      <P>
        Please visit us at <a href="https://codeshift.co">codeshift.co</a>
      </P>
    </ContentBlock>
  </Layout>
)

export default IndexPage
